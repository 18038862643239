import { reactive, toRefs, onBeforeMount, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import usebase from '@/hooks/usebase';
import usecountdown from '@/hooks/usecountdown';
import logo from '@/components/logo';
import { getVerify, userVerify, changePassword } from '@/api/user';
export default {
  name: 'password',
  props: [],
  components: {
    logo
  },

  setup() {
    const router = useRouter();
    const {
      isPhone,
      isMessage
    } = usebase();
    const {
      counttetx,
      isdisabled,
      timer,
      countdown
    } = usecountdown();
    const state = reactive({
      nextnum: 1,
      codeform: {
        phone: '',
        captcha: '',
        type: 'rest_pass'
      },
      passform: {
        password: '',
        rePassword: ''
      },
      lookchecked: true
    });
    const refState = toRefs(state);

    const userXyi = () => {
      router.push('/useragreement');
    };

    const userYsi = () => {
      router.push('/privacypolicy');
    };

    const goLogin = () => {
      setTimeout(() => {
        router.push('/login');
      }, 1000);
    };

    const goBack = () => {
      router.push('/login');
    }; //  获取验证码


    const getCode = () => {
      if (!state.codeform.phone) {
        isMessage('warning', '请输入手机号');
        return;
      }

      if (!isPhone(state.codeform.phone)) {
        isMessage('warning', '请输入合法手机号');
        return;
      }

      getVerify(state.codeform).then(res => {
        let {
          status,
          msg
        } = res;

        if (status == 200) {
          isMessage('success', msg || '验证码发送成功');
          return;
        } else {
          isMessage('error', msg || '验证码发送失败');
          countdown();
        }
      });
    }; // 校验用户是否存在


    const handlerLogin = () => {
      if (!state.codeform.phone) {
        isMessage('warning', '请输入手机号');
        return;
      }

      if (!isPhone(state.codeform.phone)) {
        isMessage('warning', '请输入合法手机号');
        return;
      }

      if (!state.codeform.captcha) {
        isMessage('warning', '请输入手机号');
        return;
      }

      let data = {
        mobile: state.codeform.phone,
        captcha: state.codeform.captcha
      };
      userVerify(data).then(res => {
        let {
          status,
          msg,
          data
        } = res;

        if (status == 200) {
          let {
            exist
          } = data;

          if (exist == 1) {
            state.nextnum = 2;
          } else {
            isMessage('error', '用户不存在，请前往注册');
            return;
          }
        } else {
          isMessage('error', msg || '操作失败');
          return;
        }
      });
    };

    const changePass = () => {
      let {
        password,
        rePassword
      } = state.passform;

      if (!state.codeform.phone) {
        isMessage('warning', '请输入手机号');
        return;
      }

      if (!password || !rePassword) {
        isMessage('warning', '请输入密码');
        return;
      }

      if (password.length < 6 || rePassword.length < 6) {
        isMessage('warning', '密码长度不能低于6位');
        return;
      }

      if (password !== rePassword) {
        isMessage('warning', '两次输入的密码不一致');
        return;
      }

      state.passform.phone = state.codeform.phone;
      changePassword(state.passform).then(res => {
        let {
          status,
          msg
        } = res;

        if (status == 200) {
          isMessage('success', '密码重置成功，请前往登录');
          goLogin();
        } else {
          isMessage('error', msg);
          return;
        }
      });
    };

    onBeforeMount(() => {});
    onMounted(() => {});
    return { ...refState,
      userXyi,
      userYsi,
      getCode,
      handlerLogin,
      changePass,
      goBack,
      counttetx,
      isdisabled,
      timer
    };
  }

};